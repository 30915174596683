import React, { useState } from "react";
import { authLoginService } from "../../config/user";
import { useDispatch, useSelector } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setCurrentUser } from '../../redux/actions/auth';
import LoaderSvg from '../../assets/images/loader.svg';
import { validateField } from '../../util/helpers';

const Login = ({ setIsLogin }) => {


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user)

  const onSubmit = async (e) => {
    e.preventDefault();
    let validate = true

    if (email.trim() === "") {
      validateField("email", "Email field is required")
      validate = false
    }

    // if (phone.trim().length != 10) {
    //   validateField("phone", "Phone Number should be 10 numbers only")
    //   validate = false
    // }

    if (password.trim() === "") {
      validateField("password", "Password field is required")
      validate = false
    }

    if (password.trim().length < 8) {
      validateField("password", "Password should be minimum 8 characters")
      validate = false
    }


    if (!validate) {
      toast("Please fill required fields")
      return
    }
    try {
      setLoader(true)
      const res = await authLoginService({

        email,
        password
      })

      if (res.data.token) {



        dispatch(setCurrentUser(res.data.token, () => {

          toast("Login Succesfully")
          navigate('/', { replace: true })
          setLoader(false)
          setIsLogin(true)

        }));


      }
    }
    catch (err) {
      setLoader(false)
      if (err.response.data.message) {
        toast(err.response.data.message)
      }

    }
  }

  return <div class="container-fluid page-body-wrapper full-page-wrapper page-login">
    <div class="content-wrapper d-flex align-items-center auth px-0">
      <div class="row w-100 mx-0">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-center py-5 px-4 px-sm-5">
            <div class="brand-logo">
              <h3>CCL <span>Labs</span></h3>
            </div>
            <h4>Admin Login</h4>
            <form class="pt-3" onSubmit={onSubmit}>
              <div class="form-group">
                <input type="text" class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Email Address" name="email" onChange={(e) => { setEmail(e.target.value); }} />
              </div>
              <div class="form-group">
                <input type="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" name="password" onChange={(e) => { setPassword(e.target.value); }} />
              </div>
              <div class="mt-3">
                <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type="submit" >SIGN IN</button>
              </div>



            </form>
          </div>
        </div>
      </div>
    </div >
  </div >
};

export default Login;
