import React, { useState, useEffect } from "react";
import Layout from "../../../layout";
import { toast } from "react-toastify";
import LoaderSvg from "../../../../assets/images/loader.svg";
import { Link } from "react-router-dom";

import { validateField } from "../../../../util/helpers";
import {
    addTestService,

} from "../../../../config/test";
import { useDispatch, useSelector } from "react-redux";

const AddTest = () => {
    const user = useSelector((state) => state.auth.user);

    const [name, setname] = useState("");
    const [code, setcode] = useState("");
    const [price, setPrice] = useState("");
    const [loinc, setloinc] = useState("");
    const [turnaround, setturnaround] = useState("");
    const [loader, setLoader] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        let validate = true;

        if (String(name).trim() === "") {
            validateField("name", "Test Name field is required");
            validate = false;
        }

        if (String(price).trim() === "") {
            validateField("price", "Price field is required");
            validate = false;
        }
        if (String(code).trim() === "") {
            validateField("code", "Billing Code field is required");
            validate = false;
        }

        if (String(loinc).trim() === "") {
            validateField("loinc", "loinc Code field is required");
            validate = false;
        }
        if (String(turnaround).trim() === "") {
            validateField("turnaround", "Turnaround Time field is required");
            validate = false;
        }

        if (!validate) {
            toast("Please fill required fields");
            return;
        }
        try {
            setLoader(true);
            const res = await addTestService({
                name,
                price,
                code,
                loinc,
                turnaround,

            });

            if (res.data.success) {

                setLoader(false);

                toast("Test Created Successfully");

                window.scrollTo(500, 0);

            }
        } catch (err) {
            setLoader(false);
            console.log(err);
        }
    };


    return (
        <Layout
            header="Add Test"
            sideBtn={
                <Link to="/tests" class="add btn btn-primary todo-list-add-btn">
                    Go Back
                </Link>
            }
        >
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <form class="forms-sample" onSubmit={(e) => onSubmit(e)}>

                                <div class="form-group">
                                    <label>Test Name</label>
                                    <input
                                        type="text"

                                        class="form-control text-capitalize form-control-lg"
                                        placeholder="Test Name"
                                        name="name"
                                        value={name}
                                        onChange={(e) => { setname(e.target.value); }}
                                    />
                                </div>
                                <div class="form-group">
                                    <label>Billing Code </label>
                                    <input
                                        type="text"

                                        class="form-control text-capitalize form-control-lg"
                                        placeholder="Billing Code "
                                        name="code"
                                        value={code}
                                        onChange={(e) => { setcode(e.target.value); }}
                                    />
                                </div>
                                <div class="form-group">
                                    <label>Price</label>
                                    <input
                                        type="text"

                                        class="form-control text-capitalize form-control-lg"
                                        placeholder="Price"
                                        name="price"
                                        value={price}
                                        onChange={(e) => { setPrice(e.target.value); }}
                                    />
                                </div>

                                <div class="form-group">
                                    <label>LOINC</label>
                                    <input
                                        type="text"

                                        class="form-control text-capitalize form-control-lg"
                                        placeholder="LOINC"
                                        name="loinc"
                                        value={loinc}
                                        onChange={(e) => { setloinc(e.target.value); }}
                                    />
                                </div>
                                <div class="form-group">
                                    <label>Turn Around Time</label>
                                    <input
                                        type="text"

                                        class="form-control text-capitalize form-control-lg"
                                        placeholder="Turn Around Time"
                                        name="turnaround"
                                        value={turnaround}
                                        onChange={(e) => { setturnaround(e.target.value); }}
                                    />
                                </div>
                                <button type="submit" class="btn btn-primary me-2">
                                    {loader ? (
                                        <img
                                            src={LoaderSvg}
                                            style={{ padding: "0 7px" }}
                                        />
                                    ) : (
                                        "Create"
                                    )}
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AddTest;
