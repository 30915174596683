
import {

    SET_NEWS,
    SET_SEARCH_NEWS,

} from '../actions/types'

const initialState = {
    isAuthenticated: false,
    token: null,
    tokenExpired: false,
    news: [],
    searchedNews: null,

}

export default function (state = initialState, action) {
    switch (action.type) {

        case SET_NEWS:
            return {
                ...state,
                news: action.payload
            }
        case SET_SEARCH_NEWS:
            return {
                ...state,
                searchedNews: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
