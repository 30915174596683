import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import { Link } from "react-router-dom";
import { getAllTests } from "../../../../redux/actions/tests";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { getTestListService } from '../../../../config/test'


const Tests = () => {
    const [tests, setTests] = useState([]);
    const [search, setSearch] = useState("");

    const [csvMaintainence, setCsvMaintainence] = useState([]);
    const [areaId, setAreaId] = useState("");
    const [category, setCategory] = useState("");
    const [month, setmonth] = useState(new Date().getMonth() + 1);
    const [year, setyear] = useState(new Date().getFullYear());
    const testsData = useSelector((state) => state.tests.tests);
    const [totalTests, SetTotalTests] = useState(0);
    const [page, setPage] = useState(1);
    const [activeItem, setActiveItem] = useState({});
    const [status, setStatus] = useState("pending");
    const [closeModal, setCloseModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        onGetAllTests();
    }, [page]);


    const onGetAllTests = async (
        filterQuery = `?page=${page}`
    ) => {
        try {
            let res = await getTestListService(filterQuery);
            setTests(res.data.test);
            SetTotalTests(res.data.total);

        } catch (e) {
            console.log(e);
        }
    };


    const renderTest = (item, key) => {
        return (
            <tr
                onClick={() => {
                    setActiveItem(item);
                    setCloseModal(true);
                }}
                style={{ cursor: "pointer" }}
            >

                <td class="text-capitalize">{(key + 1) + ((page - 1) * 10)}</td>
                <td class="text-capitalize">{item.name}</td>
                <td class="text-capitalize">{item.code}</td>
                <td class="text-capitalize">${item.price || 0}</td>
                <td class="text-capitalize">{item.loinc || "N/A"}</td>
                <td class="text-capitalize">{item.turnaround || "N/A"}</td>

            </tr>
        );
    };
    return (
        <Layout
            header="Test Records"
            sideBtn={
                <Link
                    to="/tests/add-test"
                    class="add btn btn-primary todo-list-add-btn"
                >
                    Add Test +
                </Link>
            }
        >

            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <h4 class="card-title">Records</h4>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>


                                        <th>S.No.</th>
                                        <th>Test Name</th>
                                        <th>Billing Code</th>
                                        <th>Price</th>
                                        <th>Loinc</th>
                                        <th>Turn Around Time</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {tests &&
                                        tests.length > 0 &&
                                        tests.map((i, key) => {
                                            return renderTest(i, key);
                                        })}
                                </tbody>
                            </table>
                            {totalTests > 10 && (
                                <div>
                                    <ul className="pagination">
                                        {page > 1 ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page - 1)}>Prev</a>
                                            </li>
                                        ) : null}
                                        <li>
                                            <a onClick={() => setPage(page)}>{page}</a>
                                        </li>
                                        {page * 10 < totalTests ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 1)}>
                                                    {page + 1}
                                                </a>
                                            </li>
                                        ) : null}
                                        {(page + 2) * 10 < totalTests ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 2)}>
                                                    {page + 2}
                                                </a>
                                            </li>
                                        ) : null}
                                        {(page + 3) * 10 < totalTests ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 3)}>
                                                    {page + 3}
                                                </a>
                                            </li>
                                        ) : null}
                                        {page * 10 < totalTests ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 1)}>Next</a>
                                            </li>
                                        ) : null}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
};

export default Tests;
