import React from "react";
import { Link } from "react-router-dom";

const NavBar = ({ onLogOut, user }) => {
  return (
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li class="nav-item">
          <Link class="nav-link" to="/">
            <i class="mdi mdi-grid-large menu-icon"></i>
            <span class="menu-title">Dashboard</span>
          </Link>
        </li>

        {["super-admin"].includes(user.role) && (
          <>
            <li class="nav-item nav-category">Data Management</li>

            {/* <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#ui-basic"
                aria-expanded="false"
                aria-controls="ui-basic"
              >
                <i class="menu-icon mdi mdi-floor-plan"></i>
                <span class="menu-title">Patient Management</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="ui-basic">
                <ul class="nav flex-column sub-menu">
                 
                  <li class="nav-item">
                    {" "}
                    <Link class="nav-link" to="/patient">
                      View All
                    </Link>
                  </li>
                </ul>
              </div>
            </li> */}

            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#property-management"
                aria-expanded="false"
                aria-controls="property-management"
              >
                <i class="menu-icon mdi mdi-floor-plan"></i>
                <span class="menu-title">Insights</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="property-management">
                <ul class="nav flex-column sub-menu">

                  <li class="nav-item">

                    <Link class="nav-link" to="/insights/add-insight">
                      Add Insight
                    </Link>
                  </li>

                  <li class="nav-item">

                    <Link class="nav-link" to="/insights">
                      View All
                    </Link>
                  </li>


                </ul>
              </div>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#area-management"
                aria-expanded="false"
                aria-controls="area-management"
              >
                <i class="menu-icon mdi mdi-floor-plan"></i>
                <span class="menu-title">News</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="area-management">
                <ul class="nav flex-column sub-menu">
                  {["super-admin"].includes(user.role) && (
                    <li class="nav-item">

                      <Link class="nav-link" to="/news/add-news">
                        Add News
                      </Link>
                    </li>
                  )}
                  <li class="nav-item">

                    <Link class="nav-link" to="/news">
                      View All
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#job-management"
                aria-expanded="false"
                aria-controls="job-management"
              >
                <i class="menu-icon mdi mdi-floor-plan"></i>
                <span class="menu-title">Jobs</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="job-management">
                <ul class="nav flex-column sub-menu">
                  {["super-admin"].includes(user.role) && (
                    <li class="nav-item">

                      <Link class="nav-link" to="/job/add-job">
                        Add Job
                      </Link>
                    </li>
                  )}
                  <li class="nav-item">

                    <Link class="nav-link" to="/jobs">
                      View All
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#faq-management"
                aria-expanded="false"
                aria-controls="faq-management"
              >
                <i class="menu-icon mdi mdi-floor-plan"></i>
                <span class="menu-title">Faqs</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="faq-management">
                <ul class="nav flex-column sub-menu">
                  {["super-admin"].includes(user.role) && (
                    <li class="nav-item">

                      <Link class="nav-link" to="/faqs/add-faq">
                        Add Faq
                      </Link>
                    </li>
                  )}
                  <li class="nav-item">

                    <Link class="nav-link" to="/faqs">
                      View All
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#orders-management"
                aria-expanded="false"
                aria-controls="orders-management"
              >
                <i class="menu-icon mdi mdi-floor-plan"></i>
                <span class="menu-title">Orders</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="orders-management">
                <ul class="nav flex-column sub-menu">
                  {["super-admin"].includes(user.role) && (
                    <li class="nav-item">

                      <Link class="nav-link" to="/orders">
                        View All
                      </Link>
                    </li>
                  )}
                  {/* <li class="nav-item">

                    <Link class="nav-link" to="/faqs">
                      View All
                    </Link>
                  </li> */}
                </ul>
              </div>
            </li>
          </>
        )}

        {["super-admin"].includes(user.role) && (
          <>
            <li class="nav-item nav-category">Test Menu</li>

            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#maintainence-manager"
                aria-expanded="false"
                aria-controls="maintainence-manager"
              >
                <i class="menu-icon mdi mdi-floor-plan"></i>
                <span class="menu-title">Tests</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="maintainence-manager">
                <ul class="nav flex-column sub-menu">

                  <li class="nav-item">

                    <Link class="nav-link" to="/tests/add-test">
                      Add Test
                    </Link>
                  </li>
                  <li class="nav-item">

                    <Link class="nav-link" to="/tests/bulk-upload">
                      Bulk Upload
                    </Link>
                  </li>

                  <li class="nav-item">

                    <Link class="nav-link" to="/tests">
                      View All
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            {/* <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#electricity-manager"
                aria-expanded="false"
                aria-controls="electricity-manager"
              >
                <i class="menu-icon mdi mdi-floor-plan"></i>
                <span class="menu-title">Electricity</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="electricity-manager">
                <ul class="nav flex-column sub-menu">
                  {["manager"].includes(user.role) && (
                    <>
                      <li class="nav-item">
                        <Link class="nav-link" to="/electricity/add-bill">
                          Generate Bill
                        </Link>
                      </li>
                      <li class="nav-item">
                        <Link class="nav-link" to="/electricity/pay-bill">
                          Pay Bill
                        </Link>
                      </li>
                    </>
                  )}
                  <li class="nav-item">
                    <Link class="nav-link" to="/electricity">
                      View All Bills
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#water-manager"
                aria-expanded="false"
                aria-controls="water-manager"
              >
                <i class="menu-icon mdi mdi-floor-plan"></i>
                <span class="menu-title">Water</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="water-manager">
                <ul class="nav flex-column sub-menu">
                  {["manager"].includes(user.role) && (
                    <li class="nav-item">
                      {" "}
                      <Link class="nav-link" to="/water/add-bill">
                        Generate Bill
                      </Link>
                    </li>
                  )}
                  {["manager"].includes(user.role) && (
                    <li class="nav-item">
                      {" "}
                      <Link class="nav-link" to="/water/pay-bill">
                        Pay Bill
                      </Link>
                    </li>
                  )}

                  <li class="nav-item">
                    {" "}
                    <Link class="nav-link" to="/water">
                      View All
                    </Link>
                  </li>
                </ul>
              </div>
            </li> */}
          </>
        )}

        {["staff"].includes(user.role) && (
          <>
            <li class="nav-item nav-category">Bill Management</li>

            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#ui-basic"
                aria-expanded="false"
                aria-controls="ui-basic"
              >
                <i class="menu-icon mdi mdi-floor-plan"></i>
                <span class="menu-title">Maintainence</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="ui-basic">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item">
                    {" "}
                    <Link class="nav-link" to="/maintainence/add-bill">
                      Generate Bill
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/maintainence/pay-bill">
                      Pay Bill
                    </Link>
                  </li>
                  <li class="nav-item">
                    {" "}
                    <Link class="nav-link" to="/maintainence">
                      View All
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#electricity-staff"
                aria-expanded="false"
                aria-controls="electricity-staff"
              >
                <i class="menu-icon mdi mdi-floor-plan"></i>
                <span class="menu-title">Electricity</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="electricity-staff">
                <ul class="nav flex-column sub-menu">
                  {["staff"].includes(user.role) && (
                    <>
                      <li class="nav-item">
                        <Link class="nav-link" to="/electricity/add-bill">
                          Generate Bill
                        </Link>
                      </li>
                      <li class="nav-item">
                        <Link class="nav-link" to="/electricity/pay-bill">
                          Pay Bill
                        </Link>
                      </li>
                    </>
                  )}
                  <li class="nav-item">
                    <Link class="nav-link" to="/electricity">
                      View All Bills
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#water-staff"
                aria-expanded="false"
                aria-controls="water-staff"
              >
                <i class="menu-icon mdi mdi-floor-plan"></i>
                <span class="menu-title">Water</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="water-staff">
                <ul class="nav flex-column sub-menu">
                  {["staff"].includes(user.role) && (
                    <>
                      <li class="nav-item">
                        {" "}
                        <Link class="nav-link" to="/water/add-bill">
                          Generate Bill
                        </Link>
                      </li>
                      <li class="nav-item">
                        {" "}
                        <Link class="nav-link" to="/water/pay-bill">
                          Pay Bill
                        </Link>
                      </li>
                    </>
                  )}
                  <li class="nav-item">
                    {" "}
                    <Link class="nav-link" to="/water">
                      View All
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </>
        )}

        <li class="nav-item nav-category">Settings</li>
        {/* <li class="nav-item">
                    <Link class="nav-link" to="/change-password">
                        <i class="menu-icon mdi mdi mdi-account-key"></i>
                        <span class="menu-title">Change Password</span>
                    </Link>
                </li> */}
        <li class="nav-item">
          <a
            class="nav-link"
            href="/login"
            onClick={(e) => {
              e.preventDefault();
              onLogOut();
            }}
          >
            <i class="menu-icon mdi mdi mdi-logout"></i>
            <span class="menu-title">Logout</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};
export default NavBar;
