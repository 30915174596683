import React, { useState, useEffect } from "react";
import Layout from "../../../layout";
import { updateStaffService } from '../../../../config/user';
import { toast } from 'react-toastify';
import LoaderSvg from '../../../../assets/images/loader.svg'
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { validateField } from '../../../../util/helpers'
import { addInsightService, updateInsightService } from '../../../../config/insights';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import 'draft-js/dist/Draft.css';
const AddInsight = ({ edit = false, activeItem = false, setCloseModal = () => { }, onGetAllInsights = () => { } }) => {
    const [title, setTitle] = useState("")
    const [imageUrl, setImageUrl] = useState(null)
    const [image, setImage] = useState("");
    const [desc, setDesc] = useState(EditorState.createEmpty())
    const [descText, setDescText] = useState("")
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch();
    const insights = useSelector(state => state.insights.insights)

    const onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setImage(img)
            setImageUrl(URL.createObjectURL(img));
        }
    };


    const onEditorStateChange = (editorState) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(rawContentState)
        setDesc(editorState)
        setDescText(markup)
    }


    // const navigate = useNavigate()
    useEffect(() => {
        if (edit) {

            setTitle(activeItem.title)
            setImageUrl(activeItem.image)
            if (activeItem.desc) {
                let descTe = EditorState.createWithContent(
                    ContentState.createFromBlockArray(

                        convertFromHTML(activeItem.desc)
                    )
                )

                setDesc(descTe)
                const rawContentState = convertToRaw(descTe.getCurrentContent());
                const markup = draftToHtml(rawContentState)
                setDescText(markup)
            }
        }
    }, [])

    const onSubmit = async (e) => {

        e.preventDefault();
        let validate = true

        if (title.trim() === "") {
            validateField("title", "Title field is required")
            validate = false
        }

        if (descText.trim() === "") {
            validateField("descText", "Description field is required")
            validate = false
        }

        if (!validate) {
            toast("Please fill required fields")
            return
        }

        try {
            setLoader(true)
            let fd = new FormData()
            let data = {
                title,
                desc: descText,
                image,
            }

            for (let key in data) {
                fd.append(key, data[key])
            }
            const res = await addInsightService(fd)

            if (res.data.status) {
                toast("Insight Added Successfully")
                setLoader(false)
                setTitle("")
                setDesc("")
                // navigate("/news")

            }
        }
        catch (err) {
            setLoader(false)
            console.log(err)

        }
    }
    const onUpdate = async (e) => {

        e.preventDefault();
        let validate = true

        if (title.trim() === "") {
            validateField("title", "Title field is required")
            validate = false
        }
        if (desc.length == 0) {
            validateField("desc", "Description field is required")
            validate = false
        }

        if (!validate) {
            toast("Please fill required fields")
            return
        }

        try {
            setLoader(true)
            let fd = new FormData()
            let data = {
                title,
                desc: descText,
                _id: activeItem._id
            }
            if (image) {
                data = {
                    ...data, image: image
                }
            }

            for (let key in data) {
                fd.append(key, data[key])
            }

            const res = await updateInsightService(fd)

            if (res.data.status) {

                setLoader(false)
                setCloseModal(false)
                toast("Insight Updated Successfully")
                onGetAllInsights()
            }
        }
        catch (err) {
            setLoader(false)
            console.log(err)

        }
    }



    const render = () => {
        return (
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            {!edit &&
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h4 class="card-title">Add Insight</h4>
                                    </div>
                                    <div className='col-md-6 text-end'>

                                    </div>
                                </div>
                            }

                            <form class="forms-sample" onSubmit={(e) => { edit ? onUpdate(e) : onSubmit(e) }}>
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Title</label>
                                    <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Title" value={title} name="title" onChange={(e) => { setTitle(e.target.value); }} />
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Upload Image</label>
                                    <div class="image-input">
                                        <input type="file" accept="image/*" id="imageInput" onChange={onImageChange} />
                                        <label for="imageInput" class="image-button"><i class="mdi mdi-image"></i> <span>Choose image</span></label>


                                        {imageUrl &&
                                            <img src={imageUrl} height={150} width={150} />
                                        }
                                        <span class="change-image">Choose different image</span>

                                    </div>
                                    {/* <img src={imageUrl} width={150} height={150} />

                                    <input type="file" name="myImage" onChange={onImageChange} /> */}
                                </div>

                                <div class="form-group">
                                    <label>Description</label>
                                    <Editor
                                        // name="desc"
                                        wrapperClassName="wrapper-class wrapperClassName"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        editorState={desc}
                                        onEditorStateChange={onEditorStateChange}

                                    />
                                </div>

                                <button type="submit" class="btn btn-primary me-2">{loader ? <img src={LoaderSvg} style={{ padding: "0 7px" }} /> : "Submit"}</button>
                                {/* <button class="btn btn-light">Cancel</button> */}
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
    return !edit ? (
        <Layout header="Add New Insight" sideBtn={<Link to="/insights" class="add btn btn-primary todo-list-add-btn" > Go Back</Link>}>

            {render()}
        </Layout >
    ) :
        render()

}

export default AddInsight;