
import {

    SET_TESTS,
    SET_SEARCH_TESTS,

} from '../actions/types'

const initialState = {
    isAuthenticated: false,
    token: null,
    tokenExpired: false,
    tests: [],
    searchedtests: null,

}

export default function (state = initialState, action) {
    switch (action.type) {

        case SET_TESTS:
            return {
                ...state,
                tests: action.payload
            }
        case SET_SEARCH_TESTS:
            return {
                ...state,
                searchedTests: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
