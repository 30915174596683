import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import { Link } from "react-router-dom";
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import LabUsModal from "../../../../components/Modal";
import { getOrdersListService, uploadReportService } from '../../../../config/order'
import { render } from "@testing-library/react";
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [search, setSearch] = useState("");
    const [periodFrom, setPeriodFrom] = useState("");
    const [periodTo, setPeriodTo] = useState("");
    const [totalOrders, settotalOrders] = useState(0);
    const [page, setPage] = useState(1);
    const [report, setReport] = useState("");
    const [activeItem, setActiveItem] = useState({});
    const [closeModal, setCloseModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [pdfFileUrl, setPdfFileUrl] = useState(null)
    const dispatch = useDispatch();

    const onPdfFileUpload = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];

            setReport(img)
            setPdfFileUrl(URL.createObjectURL(img));

        }
    };
    const onUpload = async (e, id) => {

        e.preventDefault();

        try {
            setLoader(true)
            let fd = new FormData()
            let data = {
                id,
                report,
            }

            for (let key in data) {
                fd.append(key, data[key])
            }
            const res = await uploadReportService(fd)

            if (res.data.status) {
                toast("Report uploaded Successfully")
                setLoader(false)


            }
        }
        catch (err) {
            setLoader(false)
            console.log(err)

        }
    }
    useEffect(() => {
        onGetAllOrders();
    }, [page]);



    const onGetAllOrders = async (
        filterQuery = `?page=${page}`
    ) => {
        try {
            let res = await getOrdersListService(filterQuery);
            setOrders(res.data);
            settotalOrders()

        } catch (e) {
            console.log(e);
        }
    };

    const filterData = (e) => {
        e.preventDefault();
        let filterQuery = `?search=${search}&periodFrom=${periodFrom}&periodTo=${periodTo}`;
        setPage(1);
        onGetAllOrders(filterQuery);
    };

    const yearList = () => {
        let list = [];
        for (let i = 2010; i <= 2050; i++) {
            list.push(i);
        }
        return list;
    };

    const renderOrders = (item, key) => {
        return (
            <tr

            >
                {/* <td>{moment(item.created).format("DD-MM-YYYY")}</td> */}
                <td class="text-capitalize">{key + 1}</td>

                <td class="text-capitalize">{item.orderId}</td>
                <td class="text-capitalize">{moment(item.createdAt).format("DD-MM-YYYY")}</td>
                <td class="text-capitalize">
                    {item.user.firstName + " " + item.user.middleName + " " + item.user.lastName}
                    {/* <a style={{ width: '20px', marginRight: '25px' }} href="/" onClick={(e) => {
                        e.preventDefault()
                        setActiveItem(item);
                        setCloseModal(true);
                    }}> <i class="menu-icon mdi mdi-pencil"
                        style={{ cursor: "pointer" }}></i>
                    </a> */}
                    {/* <a style={{ width: '35px' }} href="/" onClick={(e) => {
                        e.preventDefault()
                        if (window.confirm("Are you sure want to delete?")) { onDeleteInsight(item._id) }

                    }}> <i class="menu-icon mdi mdi-delete"
                        style={{ cursor: "pointer" }}></i>
                    </a> */}

                </td>
                <td>
                    <ul className="list-disc">
                        {item.cart && item.cart.map((citem, key) => {
                            return <li key={key}>{citem.name}</li>
                        })}
                    </ul>
                </td>
                <td class="text-capitalize">{item.total}</td>
                <td class="text-capitalize">
                    <div class="image-input">
                        {pdfFileUrl ?
                            <div className="row">
                                <input type="button" id="imageInput" onClick={(e) => onUpload(e, item._id)} />
                                <label for="imageInput" class="image-button"><i class="mdi mdi-image"></i> <span>Upload</span></label>

                            </div>
                            :

                            <div>
                                <input type="file" accept="*.pdf" id="imageInput" onChange={onPdfFileUpload} />
                                <label for="imageInput" class="image-button"><i class="mdi mdi-image"></i> <span>Select file</span></label>

                            </div>}


                        {pdfFileUrl && <div>
                            <input type="file" accept="*.pdf" class="image-button" id="selectedImageInput" onClick={onPdfFileUpload} />

                            <label for="selectedImageInput" class="image-button"><span> {report.name}</span></label>
                        </div>
                        }
                        <span class="change-image">Choose different report</span>

                    </div>
                </td>

            </tr >
        );
    };
    return (
        <Layout
            header="Orders Record"
        // sideBtn={
        //     <Link
        //         to="/insights/add-insight"
        //         class="add btn btn-primary todo-list-add-btn"
        //     >
        //         Add Insight +
        //     </Link>
        // }
        >
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        {/* <div className="row">
                            <div className="col">
                                <h4>Filter</h4>
                            </div>
                        </div> */}
                        <form onSubmit={filterData}>

                            <div className="row">
                                <div className="col-md-4 col-12">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="exampleInputUsername1"
                                            placeholder="Find By Patient..."
                                            value={search}
                                            name="search"
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                            }}
                                        />
                                    </div>


                                </div>
                                <div className="col-md-4 col-12">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="exampleInputUsername1"
                                            placeholder="Find By Order Id..."
                                            value={search}
                                            name="search"
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                            }}
                                        />
                                    </div>


                                </div>
                                <div className="row">
                                    <div className="col-md-4 col-12">
                                        <div class="form-group">
                                            <DatePicker
                                                className="form-control"
                                                selected={periodFrom}
                                                dateFormat={"dd-MM-yyyy"}
                                                // onSelect={handleDateSelect}
                                                placeholderText="Period From"
                                                onChange={(e) => {
                                                    setPeriodFrom(e);
                                                }} //only when value has changed
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div class="form-group">
                                            <DatePicker
                                                className="form-control"
                                                selected={periodFrom}
                                                dateFormat={"dd-MM-yyyy"}
                                                // onSelect={handleDateSelect}
                                                placeholderText="Period From"
                                                onChange={(e) => {
                                                    setPeriodTo(e);
                                                }} //only when value has changed
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="row  btn-section">
                                            <div class="col-md-4 col-12 px-1">
                                                <button type="submit" class="btn btn-primary filter-btn">
                                                    <i class="mdi mdi-filter"></i> <span>Filter</span>
                                                </button>
                                            </div>
                                            <div class="col-md-4 col-12 px-1">
                                                <button
                                                    type="button"
                                                    class="btn btn-primary filter-btn reset-btn"
                                                    onClick={() => {
                                                        setSearch("");

                                                        onGetAllOrders();
                                                    }}
                                                >
                                                    <i class="mdi mdi-close"></i> <span>Clear</span>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <h4 class="card-title">Records</h4>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        {/* <th>Receipt No</th> */}
                                        <th>S.No.</th>
                                        <th>Order Id</th>
                                        <th>Order Date</th>
                                        <th>
                                            Patient Name
                                        </th>
                                        <th>
                                            Test Name
                                        </th>

                                        <th>
                                            Total
                                        </th>
                                        <th>
                                            Report Available
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {orders &&
                                        orders.length > 0 &&
                                        orders.map((i, key) => {
                                            return renderOrders(i, key);
                                        })}
                                </tbody>
                            </table>
                            {totalOrders > 20 && (
                                <div>
                                    <ul className="pagination">
                                        {page > 1 ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page - 1)}>Prev</a>
                                            </li>
                                        ) : null}
                                        <li>
                                            <a onClick={() => setPage(page)}>{page}</a>
                                        </li>
                                        {page * 20 < totalOrders ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 1)}>
                                                    {page + 1}
                                                </a>
                                            </li>
                                        ) : null}
                                        {(page + 2) * 20 < totalOrders ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 2)}>
                                                    {page + 2}
                                                </a>
                                            </li>
                                        ) : null}
                                        {(page + 3) * 20 < totalOrders ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 3)}>
                                                    {page + 3}
                                                </a>
                                            </li>
                                        ) : null}
                                        {page * 20 < totalOrders ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 1)}>Next</a>
                                            </li>
                                        ) : null}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* {Object.keys(activeItem).length > 0 && (
                <LabUsModal
                    state={closeModal}
                    stateFunc={setCloseModal}
                    title="Edit Insight"
                >
                    <AddInsight edit={true} activeItem={activeItem} setCloseModal={setCloseModal} onGetAllInsights={onGetAllInsights} />
                </LabUsModal>
            )} */}
        </Layout>
    );
};

export default Orders;
