import * as React from "react";
// super admin pages
import DashBoard from "./roles/superadmin/dashboard";
import Login from "./login";
import AddInsight from "./roles/superadmin/insights/Add";
import Insights from "./roles/superadmin/insights";
import AddTest from "./roles/superadmin/tests/Add";
import Tests from "./roles/superadmin/tests";
import AddNews from "./roles/superadmin/news/Add";
import News from "./roles/superadmin/news";
import Orders from "./roles/superadmin/orders";
import BulkUpload from "./roles/superadmin/tests/bulkupload";

// import ChangePassword from './changepassword'


import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../redux/actions/auth";
import Loader from "./layout/Loader";
import AddJob from "./roles/superadmin/jobs/Add";
import Jobs from "./roles/superadmin/jobs";
import Faqs from "./roles/superadmin/faqs";
import AddFaq from "./roles/superadmin/faqs/Add";


const RootStack = () => {
  const [isLogin, setIsLogin] = React.useState(false);
  const [loader, setLoader] = React.useState(true);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  React.useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    let token = sessionStorage.getItem("token");
    if (token) {
      dispatch(
        setCurrentUser(token, () => {
          setIsLogin(true);
          setLoader(false);
        })
      );
    } else {
      setIsLogin(false);
      setLoader(false);
    }
  };

  const filterLogin = (element) => {
    return isLogin ? element : <Navigate to="/login" replace />;
  };

  const roleMap = () => {
    if (user) {
      switch (user.role) {
        case "super-admin":
          return [
            {
              path: "/",
              element: filterLogin(<DashBoard />),
            },
            {
              path: "/login",
              element: <Login setIsLogin={setIsLogin} />,
            },
            {
              path: "/insights/add-insight",
              element: filterLogin(<AddInsight />),
            },
            {
              path: "/insights",
              element: filterLogin(<Insights />),
            },
            {
              path: "/job/add-job",
              element: filterLogin(<AddJob />),
            },
            {
              path: "/jobs",
              element: filterLogin(<Jobs />),
            },
            {
              path: "/news",
              element: filterLogin(<News />),
            },
            {
              path: "/news/add-news",
              element: filterLogin(<AddNews />),
            },
            {
              path: "/tests",
              element: filterLogin(<Tests />),
            },
            {
              path: "/tests/add-test",
              element: filterLogin(<AddTest />),
            },
            {
              path: "/tests/bulk-upload",
              element: filterLogin(<BulkUpload />),
            },
            {
              path: "/faqs",
              element: filterLogin(<Faqs />),
            },
            {
              path: "/faqs/add-faq",
              element: filterLogin(<AddFaq />),
            },
            {
              path: "/orders",
              element: filterLogin(<Orders />),
            },
            // {
            //   path: "/change-password",
            //   element: filterLogin(<ChangePassword />)

            // },
          ];


      }
    }
    return [
      {
        path: "/*",
        element: filterLogin(<DashBoard />),
      },

      {
        path: "/login",
        element: <Login setIsLogin={setIsLogin} />,
      },
    ];
  };

  return loader ? (
    <Router>
      <Routes>
        <Route path="/*" element={<Loader />} />
      </Routes>
    </Router>
  ) : (
    <Router>
      <Routes>
        {roleMap().map((item, key) => {
          return <Route key={key} path={item.path} element={item.element} />;
        })}
      </Routes>
    </Router>
  );
};
export default RootStack;
