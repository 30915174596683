import { combineReducers } from "redux";
import authReducer from './auth';
import testReducer from './tests';
import insightReducer from './insights';
import newsReducer from './news';

const rootReducer = combineReducers({
    auth: authReducer,
    tests: testReducer,
    news: newsReducer,
    insights: insightReducer

})

export default rootReducer;
