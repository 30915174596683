
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from '../assets/images/logo-mini.png'
const Header = ({ header, sideBtn }) => {

    const user = useSelector(state => state.auth.user)

    return (


        <nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
            <div class="text-center navbar-brand-wrapper d-flex align-items-center">
                {/* <div class="me-3">
                    <button class="navbar-toggler navbar-toggler align-self-center" type="button">
                        <span class="icon-menu"></span>
                    </button>
                </div> */}
                <div>
                    <Link class="navbar-brand brand-logo" to="/">
                        <h3>LabUS</h3>
                    </Link>
                    <Link class="navbar-brand brand-logo-mini" to="/">
                        <img src={Logo} /></Link>
                </div>
            </div>
            <div class="navbar-menu-wrapper d-flex align-items-top">
                <ul class="navbar-nav" style={{ width: "100%" }}>
                    <li class="nav-item font-weight-semibold  d-lg-block ms-0" style={{ width: "100%" }}>
                        {
                            header ?

                                <div class="row">
                                    <h1 class="welcome-text  col-9 mb-header-text"><span class="text-black fw-bold">{header}</span>
                                    </h1>
                                    {sideBtn && (<div class="col-3 text-end">{sideBtn}</div>)}
                                </div>
                                :
                                <h1 class="welcome-text mb-header-text">

                                    Welcome, <span class="text-black fw-bold">{user.name}</span>
                                </h1>
                        }

                    </li>
                </ul>

                <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                    onClick={(e) => { e.preventDefault(); document.getElementById("sidebar").classList.toggle("active") }}>
                    <span class="mdi mdi-menu"></span>
                </button>
            </div>
        </nav>
    )
}

export default Header;