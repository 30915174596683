import publicReq, { privateReq, imageUpload } from "./axiosConfig";

export const addTestService = (data) =>
    privateReq.post("/test/add-test", data);
export const getTestListService = (data) =>
    privateReq.get("/all-test" + data);


export const updateTestService = (data) => privateReq.post("/update-test", data);
export const uploadBulkTestService = (data) =>
    privateReq.post("upload-test-csv", data);
