import axios from "axios";
// const publicReq = axios.create({
//   baseURL: "http://localhost:5000/api",
// });

// const privateReq = axios.create({
//   baseURL: "http://localhost:5000/api",
// });

// const imageReq = axios.create({
//   baseURL: "http://localhost:5000/api",
// });
const publicReq = axios.create({
  baseURL: "https://api.labus.io/api",
});

const privateReq = axios.create({
  baseURL: "https://api.labus.io/api",
});

const imageReq = axios.create({
  baseURL: "https://api.labus.io/api",
});
privateReq.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
imageReq.defaults.headers.common['Content-Type'] = 'multipart/form-data';
imageReq.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
// const refreshAccessToken = async () => {
//     let refresh_token = localStorage.getItem("refresh_token")
//     let account_id = localStorage.getItem("account_id")

//     if (refresh_token) {

//         let queryParams = `?rtoken=${refresh_token}&account_id=${account_id}`
//         try {
//             const res = await RefreshTokenService(queryParams)
//             return res.data
//         }
//         catch (e) {
//             console.log(e)
//         }
//     }
// }
// privateReq.interceptors.response.use(async (response) => {
//     const originalRequest = response.config;
//     if ((response.data.response_code == 1001 && (response.data.errors[0].code === "CT.0.0.1.10" || response.data.errors[0].code === "CT.0.0.1.3")) && !originalRequest._retry) {
//         originalRequest._retry = true
//         const token = await refreshAccessToken();

//         if (token.token != undefined) {
//             axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.token;
//             localStorage.setItem('token', token.token)
//             localStorage.setItem('refresh_token', token.refresh_token)
//             localStorage.setItem('account_id', token.id)
//             return privateReq(originalRequest);
//         }
//     }
//     return response
// }, async function (error) {
//     console.log(error)
//     // const originalRequest = error.config;
//     // if (error.response.status === 403 && !originalRequest._retry) {
//     //   originalRequest._retry = true;
//     //   const access_token = await refreshAccessToken();
//     //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
//     //   return axiosApiInstance(originalRequest);
//     // }
//     // return Promise.reject(error);
// });
export { privateReq, imageReq };
export default publicReq;
