
import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import { Link } from "react-router-dom";
import moment from 'moment'
import { useDispatch } from "react-redux";
import LabUsModal from "../../../../components/Modal";
// import { getAllInsights } from '../../../../redux/actions/insights'
import { getAllFaqs, deleteFaqService } from '../../../../config/faq'
import AddFaq from './Add'
const Faqs = () => {
    const [faqs, setFaqs] = useState([]);
    const [totalFaqs, setTotalFaqs] = useState(0);
    const [page, setPage] = useState(1);
    const [activeItem, setActiveItem] = useState({});
    const [closeModal, setCloseModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        onGetAllFaqs();
    }, [page]);



    const onGetAllFaqs = async (
        filterQuery = `?page=${page}`
    ) => {
        try {
            let res = await getAllFaqs(filterQuery);
            setFaqs(res.data.faqs);
            setTotalFaqs()

        } catch (e) {
            console.log(e);
        }
    };

    const onDeleteFaq = async (
        id
    ) => {
        try {
            let res = await deleteFaqService({ id });
            onGetAllFaqs()

        } catch (e) {
            console.log(e);
        }
    };



    const renderFaqs = (item, key) => {
        return (
            <tr

                style={{ cursor: "pointer" }}>
                {/* <td>{moment(item.created).format("DD-MM-YYYY")}</td> */}
                <td class="text-capitalize">{key + 1}</td>

                <td class="text-capitalize">{item.title}</td>
                {/* <td class="text-capitalize">{String(item.desc).substring(0, 100) + "..."}</td> */}
                <td class="text-capitalize">{moment(item.created).format("DD-MM-YYYY")}</td>
                <td >
                    <a style={{ width: '20px', marginRight: '25px' }} href="/" onClick={(e) => {
                        e.preventDefault()
                        setActiveItem(item);
                        setCloseModal(true);
                    }}> <i class="menu-icon mdi mdi-pencil"
                        style={{ cursor: "pointer" }}></i>
                    </a>
                    <a style={{ width: '35px' }} href="/" onClick={(e) => {
                        e.preventDefault()
                        if (window.confirm("Are you sure want to delete?")) { onDeleteFaq(item._id) }

                    }}> <i class="menu-icon mdi mdi-delete"
                        style={{ cursor: "pointer" }}></i>
                    </a>

                </td>

            </tr>
        );
    };
    return (
        <Layout
            header="Faq Records"
            sideBtn={
                <Link
                    to="/faqs/add-faq"
                    class="add btn btn-primary todo-list-add-btn"
                >
                    Add Faq +
                </Link>
            }
        >
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <h4 class="card-title">Records</h4>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        {/* <th>Receipt No</th> */}
                                        <th>S.No.</th>
                                        <th>Title</th>
                                        <th>Date</th>
                                        {/* <th>Desc</th> */}
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {faqs &&
                                        faqs.length > 0 &&
                                        faqs.map((i, key) => {
                                            return renderFaqs(i, key);
                                        })}
                                </tbody>
                            </table>
                            {totalFaqs > 20 && (
                                <div>
                                    <ul className="pagination">
                                        {page > 1 ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page - 1)}>Prev</a>
                                            </li>
                                        ) : null}
                                        <li>
                                            <a onClick={() => setPage(page)}>{page}</a>
                                        </li>
                                        {page * 20 < totalFaqs ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 1)}>
                                                    {page + 1}
                                                </a>
                                            </li>
                                        ) : null}
                                        {(page + 2) * 20 < totalFaqs ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 2)}>
                                                    {page + 2}
                                                </a>
                                            </li>
                                        ) : null}
                                        {(page + 3) * 20 < totalFaqs ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 3)}>
                                                    {page + 3}
                                                </a>
                                            </li>
                                        ) : null}
                                        {page * 20 < totalFaqs ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 1)}>Next</a>
                                            </li>
                                        ) : null}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {Object.keys(activeItem).length > 0 && (
                <LabUsModal
                    state={closeModal}
                    stateFunc={setCloseModal}
                    title="Edit Faq"
                >
                    <AddFaq edit={true} activeItem={activeItem} setCloseModal={setCloseModal} onGetAllFaqs={onGetAllFaqs} />
                </LabUsModal>
            )}
        </Layout>
    );
};

export default Faqs;
