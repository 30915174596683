import React, { useState, useEffect } from "react";
import Layout from "../../../layout";
import { toast } from "react-toastify";
import LoaderSvg from "../../../../assets/images/loader.svg";
import { Link } from "react-router-dom";

import { validateField } from "../../../../util/helpers";
import {
    uploadBulkTestService,

} from "../../../../config/test";
import { useDispatch, useSelector } from "react-redux";

const BulkUpload = () => {
    const user = useSelector((state) => state.auth.user);

    const [loader, setLoader] = useState(false);
    const [fileName, setFileName] = useState("")
    const [csvFile, setCsvFile] = useState({})

    const onCsvFileChange = event => {
        if (event.target.files && event.target.files[0]) {
            let fl = event.target.files[0];
            setFileName(event.target.files[0].name)
            setCsvFile(fl)
            // setImageUrl(URL.createObjectURL(fl)

            // );
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoader(true)
            let fd = new FormData()
            let data = {

                csvFile,
            }

            for (let key in data) {
                fd.append(key, data[key])
            }
            const res = await uploadBulkTestService(fd)

            if (res.data.success) {
                toast("File Uploaded Successfully")
                setLoader(false)
                setFileName("")
                setCsvFile({})


            }
        } catch (err) {
            setLoader(false);
            console.log(err);
        }
    };


    return (
        <Layout
            header="Add Test"
            sideBtn={
                <Link to="/tests" class="add btn btn-primary todo-list-add-btn">
                    Go Back
                </Link>
            }
        >
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <form class="forms-sample" onSubmit={(e) => onSubmit(e)}>

                                <div class="form-group">
                                    <label for="exampleInputUsername1">Upload Bulk(Csv)</label>
                                    <div class="image-input">
                                        <input type="file" accept=".csv" id="imageInput"
                                            onChange={onCsvFileChange}
                                        />
                                        <label for="imageInput" class="image-button"><i class="mdi mdi-image"></i> <span>Choose (CSV)</span></label>
                                        <div class="file-name">{fileName}</div>

                                    </div>
                                    <div class="form-group">
                                        <p>Note: Columns should be "name", "code", "price", "loinc", "turnaround", it will remove all the existing tests and replace with the file</p>
                                    </div>

                                </div>
                                <button type="submit" class="btn btn-primary me-2">
                                    {loader ? (
                                        <img
                                            src={LoaderSvg}
                                            style={{ padding: "0 7px" }}
                                        />
                                    ) : (
                                        "Upload"
                                    )}
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default BulkUpload;
