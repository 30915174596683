import React, { useState, useEffect } from "react";
import Layout from "../../../layout";
import { toast } from 'react-toastify';
import LoaderSvg from '../../../../assets/images/loader.svg'
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { validateField } from '../../../../util/helpers'
import { addFaqService, updateFaqService } from '../../../../config/faq';

const AddFaq = ({ edit = false, activeItem = false, setCloseModal = () => { }, onGetAllFaqs = () => { } }) => {
    const [title, setTitle] = useState("")

    const [desc, setDesc] = useState("")
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        if (edit) {
            setTitle(activeItem.title)
            setDesc(activeItem.desc)
        }
    }, [])
    const onSubmit = async (e) => {
        e.preventDefault();
        let validate = true;

        if (String(title).trim() === "") {
            validateField("title", "Title field is required");
            validate = false;
        }

        if (String(desc).trim() === "") {
            validateField("desc", "Price field is required");
            validate = false;
        }

        if (!validate) {
            toast("Please fill required fields");
            return;
        }
        try {
            setLoader(true);
            const res = await addFaqService({
                title,
                desc

            });

            if (res.data.status) {

                setLoader(false);
                setTitle("")
                setDesc("")
                toast("Faq Created Successfully");

                window.scrollTo(500, 0);

            }
        } catch (err) {
            setLoader(false);
            console.log(err);
        }
    };
    const onUpdate = async (e) => {

        e.preventDefault();
        let validate = true

        if (title.trim() === "") {
            validateField("title", "Title field is required")
            validate = false
        }
        if (desc.length == 0) {
            validateField("desc", "Description field is required")
            validate = false
        }

        if (!validate) {
            toast("Please fill required fields")
            return
        }

        try {
            setLoader(true)
            const res = await updateFaqService({
                _id: activeItem._id,
                title,
                desc,

            })

            if (res.data.status) {

                setLoader(false)
                toast("Faq Updated Successfully")
                onGetAllFaqs()
                setCloseModal(false)
            }
        }
        catch (err) {
            setLoader(false)
            console.log(err)

        }
    }

    const render = () => {
        return (
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            {!edit &&
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h4 class="card-title">Add Faq</h4>
                                    </div>
                                    <div className='col-md-6 text-end'>

                                    </div>
                                </div>
                            }

                            <form class="forms-sample" onSubmit={(e) => { edit ? onUpdate(e) : onSubmit(e) }}>
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Title</label>
                                    <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Title" value={title} name="title" onChange={(e) => { setTitle(e.target.value); }} />
                                </div>

                                <div class="form-group">
                                    <label>Description</label>
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Dsscription" value={desc} name="desc" onChange={(e) => { setDesc(e.target.value); }} style={{
                                        height: 100
                                    }}>

                                    </textarea>
                                </div>

                                <button type="submit" class="btn btn-primary me-2">{loader ? <img src={LoaderSvg} style={{ padding: "0 7px" }} /> : "Submit"}</button>

                            </form>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
    return !edit ? (
        <Layout header="Add New Faq" sideBtn={<Link to="/faq" class="add btn btn-primary todo-list-add-btn" > Go Back</Link>}>

            {render()}
        </Layout >
    ) :
        render()

}

export default AddFaq;