
import {

    SET_INSIGHTS,
    SET_SEARCH_INSIGHTS,

} from '../actions/types'

const initialState = {
    isAuthenticated: false,
    token: null,
    tokenExpired: false,
    insights: [],
    searchedInsights: null,

}

export default function (state = initialState, action) {
    switch (action.type) {

        case SET_INSIGHTS:
            return {
                ...state,
                insights: action.payload
            }
        case SET_SEARCH_INSIGHTS:
            return {
                ...state,
                searchedInsights: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
