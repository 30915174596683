import Modal from "react-modal";

const customStyles = {
  overlay: {
    position: "fixed",
    inset: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 9999,
    left: 0,
  },
  content: {
    maxWidth: "50%",
    margin: "0 auto",
  },
};

export default function LabUsModal({ children, state, stateFunc, title }) {
  return (
    <Modal isOpen={state} style={customStyles} contentLabel="Example Modal">
      <h2>{title}</h2>
      <a
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          display: "inline-block",
          fontSize: 30,
          color: "#000",
        }}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          stateFunc(false);
        }}
      >
        <i class="menu-icon mdi mdi-close"></i>
      </a>

      {children}
    </Modal>
  );
}
