import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import { Link } from "react-router-dom";
import moment from 'moment'
import { useDispatch } from "react-redux";
import LabUsModal from "../../../../components/Modal";
import { deleteJobService, getJobsListService } from '../../../../config/jobs'
import AddJob from './Add'

const Jobs = () => {
    const [jobs, setJobs] = useState([]);
    const [search, setSearch] = useState("");
    const [month, setmonth] = useState(new Date().getMonth() + 1);
    const [year, setyear] = useState(new Date().getFullYear());
    const [totalJobs, setTotalJobs] = useState(0);
    const [page, setPage] = useState(1);
    const [activeItem, setActiveItem] = useState({});
    const [closeModal, setCloseModal] = useState(false);
    const dispatch = useDispatch();
    // const insightsData 
    useEffect(() => {
        onGetAllJobs();
    }, [page]);





    const onDeleteJob = async (
        id
    ) => {
        try {
            let res = await deleteJobService({ id });
            onGetAllJobs()

        } catch (e) {
            console.log(e);
        }
    };
    const onGetAllJobs = async (
        filterQuery = `?page=${page}`
    ) => {
        try {
            let res = await getJobsListService(filterQuery);
            setJobs(res.data);
            setTotalJobs()

        } catch (e) {
            console.log(e);
        }
    };

    const filterData = (e) => {
        e.preventDefault();
        let filterQuery = `?search=${search}&month=${month}&year=${year}`;
        setPage(1);
        onGetAllJobs(filterQuery);
    };

    const yearList = () => {
        let list = [];
        for (let i = 2010; i <= 2050; i++) {
            list.push(i);
        }
        return list;
    };

    const renderJobs = (item, key) => {
        return (
            <tr

            >
                {/* <td>{moment(item.created).format("DD-MM-YYYY")}</td> */}
                <td class="text-capitalize">{key + 1}</td>

                <td class="text-capitalize">{item.title}</td>
                <td class="text-capitalize">{item.location}</td>
                <td class="text-capitalize">{item.jobType}</td>

                <td >
                    <a style={{ width: '20px', marginRight: '25px' }} href="/" onClick={(e) => {
                        e.preventDefault()
                        setActiveItem(item);
                        setCloseModal(true);
                    }}> <i class="menu-icon mdi mdi-pencil"
                        style={{ cursor: "pointer" }}></i>
                    </a>
                    <a style={{ width: '35px' }} href="/" onClick={(e) => {
                        e.preventDefault()
                        if (window.confirm("Are you sure want to delete?")) { onDeleteJob(item._id) }

                    }}> <i class="menu-icon mdi mdi-delete"
                        style={{ cursor: "pointer" }}></i>
                    </a>

                </td>
            </tr>
        );
    };
    return (
        <Layout
            header="Insights Records"
            sideBtn={
                <Link
                    to="/insights/add-insight"
                    class="add btn btn-primary todo-list-add-btn"
                >
                    Add Job +
                </Link>
            }
        >

            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <h4 class="card-title">Jobs List</h4>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        {/* <th>Receipt No</th> */}
                                        <th>S.No.</th>
                                        <th>Title</th>
                                        <th>Location</th>
                                        <th>Job Type</th>
                                        <th>
                                            Edit
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {jobs &&
                                        jobs.length > 0 &&
                                        jobs.map((i, key) => {
                                            return renderJobs(i, key);
                                        })}
                                </tbody>
                            </table>
                            {totalJobs > 20 && (
                                <div>
                                    <ul className="pagination">
                                        {page > 1 ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page - 1)}>Prev</a>
                                            </li>
                                        ) : null}
                                        <li>
                                            <a onClick={() => setPage(page)}>{page}</a>
                                        </li>
                                        {page * 20 < totalJobs ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 1)}>
                                                    {page + 1}
                                                </a>
                                            </li>
                                        ) : null}
                                        {(page + 2) * 20 < totalJobs ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 2)}>
                                                    {page + 2}
                                                </a>
                                            </li>
                                        ) : null}
                                        {(page + 3) * 20 < totalJobs ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 3)}>
                                                    {page + 3}
                                                </a>
                                            </li>
                                        ) : null}
                                        {page * 20 < totalJobs ? (
                                            <li>
                                                <a onClick={() => setPage((page) => page + 1)}>Next</a>
                                            </li>
                                        ) : null}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {Object.keys(activeItem).length > 0 && (
                <LabUsModal
                    state={closeModal}
                    stateFunc={setCloseModal}
                    title="Edit Job"
                >
                    <AddJob edit={true} activeItem={activeItem} setCloseModal={setCloseModal} onGetAllJobs={onGetAllJobs} />
                </LabUsModal>
            )}
        </Layout>
    );
};

export default Jobs;
