import publicReq, { privateReq, imageUpload } from './axiosConfig';


export const authLoginService = (data) => publicReq.post("/login-admin", data);
// export const registerService = (data) => publicReq.post("/admin-register", data);
export const addStaffService = (data) => privateReq.post("/add-staff", data);
export const updateStaffService = (data) => privateReq.post("/update-staff", data);

export const getUserService = () => privateReq.get('/get-user');
export const getAllUsersService = (data) => privateReq.get('/get-users' + data);
export const updateUserStatusService = (data) => privateReq.post("update-user-status", data);
export const ChangePasswordService = (data) => privateReq.post("/change-password", data)
