
import publicReq, { imageReq, privateReq } from './axiosConfig';



export const addNewsService = (data) => imageReq.post("/news/add-news", data,
    // {
    //     transformRequest: (data2) => data

    // }
);
export const getNewsListService = (data) =>
    privateReq.get("/news/news-list", data);

export const updateNewsService = (data) => imageReq.post("/update-news", data);
export const deleteNewsService = (data) => privateReq.delete("/delete-news/" + data.id);